
import { IProjectsState } from '@/store/modules/projects/types';
import { IProject } from '@/types/project.types';
import { Validation } from '@/ui/mixins/validation';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { IProtocol } from '@/types/common.types';

@Component({
  components: {},
})
export default class CheckProtocol extends mixins(Validation) {
  @State('projects') projectsState!: IProjectsState;
  @Action('projects/updateProject') updateProject!: (project: IProject) => Promise<void>;

  wasAllreadyFilled = false;
  protocol: IProtocol = {
    checkList: {
      'measurementLoadGrid': false,
      'checkCHP': false,
      'measurementLoadEmergency': false,
      'measurementLoadGenerator': false,
      'measurementGridGrid': false,
      'checkGrounding': false,
      'checkCommunication': false,
      'currentCheckLoad': false,
      'threePhaseCheck': false,
      'checkDcCurrent': false,
      'checkConverter': false,
      'checkTotalPlant': false,
      'checkIoModuleInverter': false,
      'checkDrm': false,
      'checkInternalPatchCabels': false,
      'checkParts': false,
      'checkConver': false,
      'checkOrderOfCircutBreaker': false,
      'checkTerminatingResistor': false,
      'checkMontage': false,
      'checkLabel': false,
      'checkAccesories': false,
      'checkConnectionAndCompare': false,
      'checkErrorVariables': false,
      'checkPilotValues': false,
      'checkGatewayCommunication': false,
      'connectEastronDevice': false,
      'updateInventory': false,
    },
    date: '',
    serialNumber: '',
    signature: '',
  }

  get project() {
    return this.projectsState.project;
  }

  get valid() {
    const isAllChecked = Object.values(this.protocol.checkList).every((point) => point);
    return isAllChecked && this.protocol.date && this.protocol.serialNumber && this.protocol.signature;
  }

  async saveProtocol() {
    const object = {
      ...this.project,
      meta: {
        ...this.project.meta,
        checkProtocol: {
          signature: this.protocol.signature,
          serialNumber: this.protocol.serialNumber,
          date: this.protocol.date,
          everyPointChecked: true,
        },
      },
    };
    await this.updateProject(object);
    this.wasAllreadyFilled = true;
  }

  preparePrintHtml() {
    // add title
    let toPrint = `<h1 class="title">${this.$t('uiComponents.settings.checkProtocol.title')}</h1>`;

    // Add all list items
    Object.entries(this.protocol.checkList).forEach(([key, value], index) => {
      const checkboxDiv = document.getElementById(`check-item-${index}`) as HTMLInputElement;
      const actualCheckbox = checkboxDiv.querySelector('input[type="checkbox"]') as HTMLInputElement;
      let checkbox = '';
      if (actualCheckbox?.checked) {
        checkbox = '<input type="checkbox" checked />'; // Unicode checkmark
      } else {
        checkbox = '<input type="checkbox" />'; // Unicode box
      }
      const checkboxHTML = `<span class="checkbox">${checkbox}</span>`;
      // eslint-disable-next-line no-useless-concat
      toPrint += `<div class="wrapper">${
        `${checkboxHTML
        }<span class="text">${this.$t(`uiComponents.settings.checkProtocol.points.${key}`)}</span>\n`}</div>`;
    });

    // Add date
    const date = new Date(this.protocol.date);
    toPrint += `<div class="field"><span class="text">${this.$t('uiComponents.settings.checkProtocol.labels.date')}: ${
      `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
    }</span></div>`;
    // Add signature
    toPrint += `<div class="field"><span class="text">${this.$t('uiComponents.settings.checkProtocol.labels.signature')}: ${
      this.protocol.signature
    }</span></div>`;
    // Add serial number
    toPrint += `<div class="field"><span class="text">${this.$t('uiComponents.settings.checkProtocol.labels.serialNumber')}: ${
      this.protocol.serialNumber
    }</span></div>`;

    return toPrint;
  }

  downloadCheckList() {
    const printWindow = window.open(
      '',
      '',
      'left=0,top=0,width=1600px,height=1600px,toolbar=0,scrollbars=0,status=0',
    )!;
    printWindow.document.write(`<!DOCTYPE html>
        <html>
          <head>
            <style>
              .rotated {
                transform: rotate(-90deg);
              }
              .checklist-container {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
              }
              .checkbox {
                font-size: 24px;
                margin-right: 10px;
              }
              .text {
                font-size: 15px;
                font-family: "Clear Sans", "Helvetica Neue", Arial, sans-serif;
              }
              .wrapper {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
              }
              .field {
              width: 40%;
              border: 1px solid black;
              padding: 10px 0 10px 5px;
              margin-top: 15px;
              border-radius: 5px;
              }
            </style>
          </head>
          <body>
            <div class="checklist-container">
                ${this.preparePrintHtml()}
            </div>
           </body>
        </html>`);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  }

  created() {
    if (this.project.meta.checkProtocol !== undefined) {
      this.protocol.signature = this.project.meta.checkProtocol.signature;
      this.protocol.serialNumber = this.project.meta.checkProtocol.serialNumber;
      this.protocol.date = this.project.meta.checkProtocol.date;
      Object.keys(this.protocol.checkList).forEach((key: string) => {
        this.protocol.checkList[key] = true;
      });
      this.wasAllreadyFilled = true;
    }
  }
}
